

import { isMq } from '../data/_variables';
let gap;
$(window).on('load scroll',function() {
  if(isMq == 'pc') {
    if( $('#bigbanner').length ) {
      gap = '50% calc(50% - ' + ( $(window).scrollTop() - $('#bigbanner').offset().top + ( $('#bigbanner').height() / 2 ) ) * 0.3 + 'px)';
      $('#bigbanner .c-box-parallax').css('background-position', gap);
    }
  }
});

$(document).ready(function() {
  if(isMq) {
    if( $('#pageVisual').length ) {
      offset = ( $('#pageVisual').height() / 2 ) - ( $(window).scrollTop() * 0.3 );
      gap = '50% calc(-100px + ' + offset + 'px)';
      $('#pageVisual').css('background-position', gap);
    }
    if( $('#bigbanner').length ) {
      gap = '50% calc(50% - ' + ( $(window).scrollTop() - $('#bigbanner').offset().top + ( $('#bigbanner').height() / 2 ) ) * 0.3 + 'px)';
      $('#bigbanner a').css('background-position', gap);
    }
  }
});