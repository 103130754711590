/**
 * @file もっと見る
 *
*/

import { activeClass } from '../data/_variables';

const $more = $('.js-more');

$more.off('click').on('click', event => {

  // デフォルトの挙動を無効化
  event.preventDefault();

  const $this = $(event.currentTarget),
        $target = $($this.attr('href')),
        $targetChild = $target.children(),
        $targetActiveChild = $target.children('.' + activeClass),
        $targetChildShow = $target.children(':lt('+ showCnt +')'),
        addCnt = parseInt($this.attr('data-more')),
        cnt = $targetChild.length,
        activeCnt = $targetActiveChild.length,
        showCnt = addCnt + activeCnt;

  if(addCnt){
    if(cnt <= showCnt){
      $this.hide();
      $targetChildShow.addClass(activeClass);
    } else {
      $targetChildShow.addClass(activeClass);
    }
  } else {
    $this.hide();
    $targetChild.addClass(activeClass);
  }

});

const $more_01 = $('.js-more-01');

$more_01.off('click').on('click', event => {
  // デフォルトの挙動を無効化
  event.preventDefault();
  const $this = $(event.currentTarget),
        $target = $($this.prev());
        if($target.hasClass('is-active')){
          $this.removeClass('is-active');
          $this.find('span').text("もっと見る");
          $target.removeClass('is-active');
        } else {
          $target.addClass('is-active');
          $this.addClass('is-active');
          $this.find('span').text("閉じる");
        }
        
});

var moreWomanList = function(){
  if(MQ == 'SP'){
    const $more_02 = $('.js-more-02');
    $('.list-woman li:gt(7)').hide();
    $('.p-woman .c-box-gray-md').hide();
    $more_02.off('click').on('click', event => {
      // デフォルトの挙動を無効化
      event.preventDefault();
      const $this = $(event.currentTarget),
            $target = $('.list-woman'),
            $target02 = $('.c-box-gray-md');

            if($target.hasClass('is-active')){
              $this.removeClass('is-active');
              $this.find('span').text("もっと見る");
              $target.removeClass('is-active');
              $('.list-woman li:gt(7)').hide();
            } else {
              $target.addClass('is-active');
              $this.addClass('is-active');
              $this.find('span').text("閉じる");
              $('.list-woman li:gt(7)').show();
              $.fn.matchHeight._update();
            }
            if($target02.hasClass('is-active')){
              $this.removeClass('is-active');
              $this.find('span').text("もっと見る");
              $target02.removeClass('is-active');
              $target02.hide();
            } else {
              $target02.addClass('is-active');
              $this.addClass('is-active');
              $this.find('span').text("閉じる");
              $target02.show();
              $.fn.matchHeight._update();
            }
    });
  } else {
    $('.list-woman li:gt(7)').show();
    $('.p-woman .c-box-gray-md').show();
    $.fn.matchHeight._update();
  }
}

var achivementDataList = function(){
  const $more_03 = $('.js-more-03');

  // check list before click
  if(MQ == 'SP'){
    if($('.c-box-achievement-list__data-list').hasClass('is-active')){
      $('.c-box-achievement-list__data-list dt:gt(4)').show();
      $('.c-box-achievement-list__data-list dd:gt(4)').show();
    } else {
      $('.c-box-achievement-list__data-list dt:gt(4)').hide();
      $('.c-box-achievement-list__data-list dd:gt(4)').hide();
    }
  }
  else {
    $('.c-box-achievement-list__data-list dt').show();
    $('.c-box-achievement-list__data-list dd').show();
  } 
}

var clickBtnMore03 = function(){
  const $more_03 = $('.js-more-03');
  $more_03.off('click').on('click', event => {
    event.preventDefault();

    //console.log("sp");
    const $this = $(event.currentTarget),
          $target = $('.c-box-achievement-list__data-list');

    
      if($target.hasClass('is-active')){
        $this.removeClass('is-active');
        $this.find('span').text("もっと見る");
        $target.removeClass('is-active');
        $('.c-box-achievement-list__data-list dt:gt(4)').hide();
        $('.c-box-achievement-list__data-list dd:gt(4)').hide();
      } else {
        $target.addClass('is-active');
        $this.addClass('is-active');
        $this.find('span').text("閉じる");
        $('.c-box-achievement-list__data-list dt:gt(4)').show();
        $('.c-box-achievement-list__data-list dd:gt(4)').show();
      }  
  });
}

var rescueList = function(){
  if(MQ == 'SP'){
    const $more_04 = $('.js-more-04');
    $('.c-list-unlist.is-js-more-04 .c-list-unlist__item:gt(4)').hide();

    $more_04.off('click').on('click', event => {
      // デフォルトの挙動を無効化
      event.preventDefault();
      const $this = $(event.currentTarget),
            $target = $('.c-list-unlist.is-js-more-04');

            if($target.hasClass('is-active')){
              $this.removeClass('is-active');
              $this.find('span').text("もっと見る");
              $target.removeClass('is-active');
              $('.c-list-unlist.is-js-more-04 .c-list-unlist__item:gt(4)').hide();
            } else {
              $target.addClass('is-active');
              $this.addClass('is-active');
              $this.find('span').text("閉じる");
              $('.c-list-unlist.is-js-more-04 .c-list-unlist__item:gt(4)').show();
            }
    });
  }
}

var moreList = function(){
  if(MQ == 'SP'){
    const $more_05 = $('.js-more-05');
    $('.c-list-unlist.is-js-more-05 .c-list-unlist__item:gt(4)').hide();
    $('.c-list-sm.is-js-more-05').hide();

    $more_05.off('click').on('click', event => {
      // デフォルトの挙動を無効化
      event.preventDefault();
      const $this = $(event.currentTarget),
            $target = $('.c-list-unlist.is-js-more-05');

            if($target.hasClass('is-active')){
              $this.removeClass('is-active');
              $this.find('span').text("もっと見る");
              $target.removeClass('is-active');
              $('.c-list-unlist.is-js-more-05 .c-list-unlist__item:gt(4)').hide();
              $('.c-list-sm.is-js-more-05').hide();
            } else {
              $target.addClass('is-active');
              $this.addClass('is-active');
              $this.find('span').text("閉じる");
              $('.c-list-unlist.is-js-more-05 .c-list-unlist__item:gt(4)').show();
              $('.c-list-sm.is-js-more-05').show();
            }
    });
  }
}

var MQ = "PC";
var oldMQ  = MQ;
var $window = $(window); 
var screenFixed = false;

/* ----------------------------------------------------------
 Check screen size
---------------------------------------------------------- */
var chkScreenSize = function () {
var winWidth = $window.outerWidth();
  if (winWidth > 896) {
    MQ = "PC";
  }
  else {
    MQ = "SP";
  }

  // trigger custom event when breakpoint change
  if (oldMQ !== MQ) {
    oldMQ = MQ;
    $window.trigger("breakpointChange");
  }
  //console.log(MQ);
};

$window.on("breakpointChange", function(){
  chkScreenSize();
  moreWomanList();
  achivementDataList();
  rescueList();
  moreList();
  clickBtnMore03();
});

$(document).ready(function(){
  chkScreenSize();
  moreWomanList();
  achivementDataList();
  rescueList();
  moreList();
  clickBtnMore03();
});