var MQ = "PC";
var oldMQ  = MQ;
var $window = $(window); 
var screenFixed = false;

/* ----------------------------------------------------------
 Check screen size
---------------------------------------------------------- */
var chkScreenSize = function () {
var winWidth = $window.outerWidth();
  if (winWidth > 896) {
    MQ = "PC";
  }
  else {
    MQ = "SP";
  }

  // trigger custom event when breakpoint change
  if (oldMQ !== MQ) {
    oldMQ = MQ;
    $window.trigger("breakpointChange");
  }
  //console.log(MQ);
};

$window.on("breakpointChange", function(){
  chkScreenSize();
  staffDotted();
});

$(document).ready(function(){
  chkScreenSize();
  staffDotted();
});

var staffDotted = function(){
  $('.c-box-staff-index__detail .c-title').each(function(){
    //console.log(($(this).data('original-text')));
    if(typeof($(this).data('original-text')) == 'undefined'){
      var originalText = $(this).text();
      $(this).data('original-text', originalText);
    }

    if(MQ == 'PC'){
      var oldText = $(this).data('original-text');
      //console.log(oldText.length);
      if(oldText.length > 38){
        var newText = oldText.substr(0, 38) + '...';
      }
      $(this).text(newText)
     } else {
       $(this).text($(this).data('original-text'));
     } 
  });
}
