/**
 * @file クリックしたらclass付与
 *
*/

import { activeClass } from '../data/_variables';

const $toggle = $('.js-toggle');

$toggle.on('click', event => {

  // デフォルトの挙動を無効化
  event.preventDefault();

  const $this = $(event.currentTarget);

  // hrefの指定先にclassを付与/除去
  const id = $this.attr('href');
  if(id != '#'){
    $(id).toggleClass(activeClass);
  }

  // data-toggleが指定されていたらグループ化
  const group = $this.attr('data-group');
  if(group){
    if($this.hasClass(activeClass)){
      $this.removeClass(activeClass);
    } else {
      $('[data-group="'+group+'"]').removeClass(activeClass);
      $this.addClass(activeClass);
    }
  } else {
    // クリックする度にclassを付与/除去
    $this.toggleClass(activeClass);
  }
  addLockScreen();

});

var addLockScreen = function(){
  if(!$('.l-header__menu-trigger').hasClass('is-active')){
    unlockScreen();
  } else {
    lockScreen();
  }
}

/* ----------------------------------------------------------
  lock screen
---------------------------------------------------------- */
var lockScreen = function() {
  var $html = $("html");
  var top   = $window.scrollTop();

  if(!$html.hasClass("is-fixed")) {
    $html.addClass("is-fixed");
    $html.css({ top: (top * -1) });
    $html.data("top", top);
    screenFixed = true;
  }
};

/* ----------------------------------------------------------
  unlock screen
---------------------------------------------------------- */
var unlockScreen = function() {
  var $html = $("html");
  var top   = $html.data("top") || 0;

  if($("html").hasClass("is-fixed")) {
    $html.removeClass("is-fixed");
    $html.css({ top: "" });
    $window.scrollTop(top);
    screenFixed = false;
  }
};

var MQ = "PC";
var oldMQ  = MQ;
var $window = $(window); 
var screenFixed = false;

/* ----------------------------------------------------------
 Check screen size
---------------------------------------------------------- */
let chkScreenSize = function () {
  let winWidth = $window.outerWidth();
    if (winWidth > 896) {
      MQ = "PC";
    }
    else {
      MQ = "SP";
    }
  
    // trigger custom event when breakpoint change
    if (oldMQ !== MQ) {
      oldMQ = MQ;
      $window.trigger("breakpointChange");
    }
    //console.log(MQ);
  };
  
  $window.on("resize", function(){
    chkScreenSize();
    addLockScreen();
  });
  
  $(function() {
    chkScreenSize();
    addLockScreen();
  });