import { isMq } from '../data/_variables';

let oldTop = null;
let newTop = null;
let height = 0;
let heightSmall = 0;
const $header = $("header");
const $sidebar = $(".l-header__menu-sidebar-fix.top");
const $window = $(window);
$window.on("scroll", function() {
  const breakpointMd = 896;
  const mqMd = window.matchMedia('(min-width:'+ breakpointMd +'px)');
  const isMq = mqMd.matches ? 'pc' : 'sp';
  newTop = $(this).scrollTop();
  //if(screenFixed) return false;

  // if(spDevices.mobile()) {
  //   height = MQ == "PC" ? 200 : 70;
  // } else {
  //   height = MQ == "PC" ? 80 : 70;
  // }
  //if(isMq == 'pc'){
  height = isMq == "pc" ? 240 : 70;
  heightSmall = isMq == "pc" ? 120 : 70;
  
  if(isMq == 'pc'){
    if (newTop > oldTop && newTop > height) {
      if(!$header.hasClass('is-hidden')){
        $header.addClass("is-hidden");
      } else {
        $header.addClass("is-hidden");
      }
      if(!$sidebar.hasClass('is-active')){
        $sidebar.addClass("is-active");
      } else {
        $sidebar.addClass("is-active");
      }
    } else if (newTop < oldTop) {
      $header.removeClass("is-hidden");
      $sidebar.removeClass("is-active");
    }
  
    if (newTop > oldTop && newTop > heightSmall) {
      if(!$header.hasClass('is-bg-white')){
        $header.addClass("is-bg-white");
      } else {
        $header.addClass("is-bg-white");
      }
      if(!$sidebar.hasClass('is-active')){
        $sidebar.addClass("is-active");
      } else {
        $sidebar.addClass("is-active");
      }
    } else if (newTop < oldTop && newTop < heightSmall) {
      $header.removeClass("is-bg-white");
      $sidebar.removeClass("is-active");
    }
  
    oldTop = newTop;
  } else if(isMq == 'sp') {
    if(newTop > 0){
      $('.l-header__logo').css('opacity', 0);
      $('.l-header__in, .l-header').css('background', 'none');
    } else {
      $('.l-header__logo').css('opacity', 1);
      $('.l-header__in, .l-header').css('background', 'linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)');
    }
  }

});
