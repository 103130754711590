
$(() => {
  $('.js-bg-generate').each((idx, el) => {
    var imgPc = $(el).data('src-pc').split(',');
    var imgSp = $(el).data('src-sp');

    var htmlPc = `<figure class="u-dn-md--down" style="background-image: url(${imgPc[0]}); background-image: -webkit-image-set(url(${imgPc[0]}) 1.0x, url(${imgPc[1] ? imgPc[1].trim() : imgPc[0]}) 2.0x);"></figure>`;
    var htmlSp = `<figure class="u-dn-md" style="background-image: url(${imgSp})"></figure>`;

    $(el).append(htmlPc, htmlSp);
  });
});
