// $('.js-video-modal-open').click(function (e) {
//   e.preventDefault();
//   const href = $(this).attr('href');

//   $(document).find(href).show();
// });

// $('.js-modal-close').click(function (e) {
//   e.preventDefault();
//   $(this).closest('.c-video-modal').hide();
// });

$('.js-video-modal-open').modaal({
	type: 'video'
});