/**
 * @file アコーディオン
 *
*/

import { activeClass } from '../data/_variables';

const $window = $(window);
const $accordion = $('.js-accordion');
const speed = 300; // 開閉スピード

/* ----------------------------------------------------------
  ObjectFitIt
---------------------------------------------------------- */
function ObjectFitIt() {
  $('img.obj-fit').each(function(){
  var imgSrc = $(this).attr('src');
  var fitType = 'cover';

  if($(this).data('fit-type')) {
    fitType = $(this).data('fit-type');
  }

  $(this).parent().css({ 'background' : 'transparent url("'+imgSrc+'") no-repeat center center/'+fitType, });
  $('img.obj-fit').css('display','none'); });
}

if('objectFit' in document.documentElement.style === false) {
  ObjectFitIt();
}


