/**
 * @file スライダー
 * http://kenwheeler.github.io/slick/
*/

import slick from '../lib/_slick.min';
import { mqMd, isMq, isMqMd, isMqMdDown } from '../data/_variables';

$('.js-slider').slick();

// top slider
let $imgSlider;
$imgSlider = $('.js-slider-hero');

// generate BG images for slider
$imgSlider.find('.u-main-slider__image').each((idx, el) => {
  var imgPc = $(el).data('src-pc').split(',');
  var imgSp = $(el).data('src-sp');

  var htmlPc = `<div class="u-main-slider__bg u-dn-md--down" style="background-image: url(${imgPc[0]}); background-image: -webkit-image-set(url(${imgPc[0]}) 1.0x, url(${imgPc[1] ? imgPc[1].trim() : imgPc[0]}) 2.0x);"></div>`;
  var htmlSp = `<div class="u-main-slider__bg u-dn-md" style="background-image: url(${imgSp})"></div>`;

  $(el).hide()
    .parent().append(htmlPc, htmlSp);
});

$imgSlider.on('init', (event, slick, x, y, z) => {
  $(event.currentTarget)
    .addClass('is-active')
    .find('.slick-slide').eq(slick.currentSlide)
    .addClass('on');
  $('.c-hero__text').addClass('on');
});

$imgSlider.slick({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  fade: true,
  cssEase: 'linear',
  infinite: true,
  swipe: false,
  rows: 0
}).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
  $('.current').text(nextSlide + 1);

  var _current = $imgSlider.find('.slick-slide').eq(currentSlide);
  var _next = $imgSlider.find('.slick-slide').eq(nextSlide);
  
  _next.addClass('on');
}).on('afterChange', (event, slick, currentSlide) => {
  $imgSlider
    .find('.slick-slide').not(':eq(' + currentSlide + ')')
    .removeClass('on');
});

// js-slider-center
$('.js-slider-center').slick({
  centerMode: true,
  centerPadding: '13%',
  slidesToShow: 3,
  arrows: true,
  pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 4000,
  rows: 0,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '10%',
        swipeToSlide: true,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 896,
      settings: {
        arrows: false,
        centerMode: true,
        autoplay: false,
        slidesToShow: 3,
        swipeToSlide: true,
        centerPadding: '15px',
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: false,
        autoplay: false,
        slidesToShow: 1,
        swipeToSlide: true,
        centerPadding: '15px',
      }
    },
  ]
});

var MQ = "PC";
var oldMQ  = MQ;
var $window = $(window); 
var screenFixed = false;

/* ----------------------------------------------------------
 Check screen size
---------------------------------------------------------- */
let chkScreenSize = function () {
let winWidth = $window.outerWidth();
  if (winWidth > 896) {
    MQ = "PC";
  }
  else {
    MQ = "SP";
  }

  // trigger custom event when breakpoint change
  if (oldMQ !== MQ) {
    oldMQ = MQ;
    $window.trigger("breakpointChange");
  }
  //console.log(MQ);
};

$window.on("breakpointChange", function(){
  chkScreenSize();
  stepSliderSP();
  accessSliderSP();
  instaSlider();
});

$(function() {
  chkScreenSize();
  stepSliderSP();
  accessSliderSP();
  instaSlider();
});

var stepSliderSP = function(){
  if(MQ == 'SP'){
    //console.log($('.js-slider-sp-dotted').hasClass('is-slider'));
    if(!$('.js-slider-sp-dotted').hasClass('is-slider')){
      //console.log(1);
      $('.js-slider-sp-dotted').addClass('is-slider');

      $('.js-slider-sp-dotted').slick({
        slidesToShow: 2,
        arrows: false,
        pauseOnHover: true,
        autoplay: false,
        dots: true,
        swipeToSlide: true,
        rows: 0,
      });
    }

  } else {
    if($('.js-slider-sp-dotted').hasClass('is-slider')){
      $('.js-slider-sp-dotted').slick('unslick').removeClass('is-slider');
    }
  }
}

var accessSliderSP = function(){
  if(MQ == 'SP'){
    //console.log($('.js-slider-sp-dotted').hasClass('is-slider'));
    if(!$('.js-slider-sp-dotted-access').hasClass('is-slider')){
      //console.log(1);
      $('.js-slider-sp-dotted-access').addClass('is-slider');

      $('.js-slider-sp-dotted-access').slick({
        slidesToShow: 1,
        arrows: false,
        pauseOnHover: true,
        autoplay: false,
        dots: true,
        swipeToSlide: true,
        rows: 0,
      });
    }

  } else {
    if($('.js-slider-sp-dotted-access').hasClass('is-slider')){
      $('.js-slider-sp-dotted-access').slick('unslick').removeClass('is-slider');
    }
  }
}

var instaSlider = function(){
    //console.log($('.js-slider-sp-dotted').hasClass('is-slider'));
    if(!$('.js-slider-list-sns').hasClass('is-slider')){
      //console.log(1);
      $('.js-slider-list-sns').addClass('is-slider');

      $('.js-slider-list-sns').slick({
        slidesToShow: 6,
        arrows: false,
        pauseOnHover: false,
        autoplay: false,
        dots: false,
        rows: 0,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1441,
            settings: {
              slidesToShow: 5,
              arrows: false,
              pauseOnHover: false,
              autoplay: false,
              dots: false,
              swipeToSlide: true,
              rows: 0,
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
              arrows: false,
              pauseOnHover: false,
              autoplay: false,
              dots: false,
              swipeToSlide: true,
              rows: 0,
            }
          },
          {
            breakpoint: 897,
            settings: {
              slidesToShow: 3,
              arrows: false,
              pauseOnHover: false,
              autoplay: false,
              dots: false,
              swipeToSlide: true,
              rows: 0,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 2,
              arrows: false,
              pauseOnHover: false,
              autoplay: false,
              dots: false,
              swipeToSlide: true,
              rows: 0,
            }
          },
        ]
      });
    }
}


