(() => {
  const cookieName = 'isLoaded';
  const expireDay = 1;

  const setCookies = (name, value, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const getCookies = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  };

  $(window).on('load', () => {
    if ($('.js-box-wave').length > 0 && !getCookies(cookieName)) {
      //setCookies(cookieName, true, expireDay);
      $('.js-box-wave').find('.c-box-wave__item').addClass('is-animated');

      if ($('.js-slider-hero').length > 0) {
        $('.js-slider-hero').slick('slickGoTo', 0);
      }
    }
  });

  $(() => {
    if ($('.js-box-wave').length > 0) {
      if (getCookies(cookieName)) {
        $('.js-box-wave').remove();
      }
      else {
        $('.js-box-wave').find('.c-box-wave__overlay').remove();
      }
    }
  });
})();
