/**
 * @file 高さ揃え
 * https://github.com/liabru/jquery-match-height
*/

import matchHeight from '../lib/_jquery.matchHeight-min';

$('.js-stretch').matchHeight();
$('.js-stretch-row').matchHeight();
$('.list-woman__item').matchHeight({
  byRow: false,
});
$.fn.matchHeight._throttle = 300;
